/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "startergatsbyblog-20211025170043-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://startergatsbyblog-20211025170043-hostingbucket-dev.s3-website-us-east-1.amazonaws.com"
};


export default awsmobile;
